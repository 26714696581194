/* eslint no-undef: "off"*/
import React, { useEffect, useState } from "react";

export default function InPostWidget(props) {
  const [showMap, setShowMap] = useState(false);
  const [point, setPoint] = useState(false);

  useEffect(() => {
    window.easyPackAsyncInit = function () {
      easyPack.init({});
      var map = easyPack.mapWidget("easypack-map", (point) => {
        setShowMap(false);
        setPoint(point);
        props.setDeliveryPointHandler(point);
      });
    };

    easyPack.init({
      defaultLocale: "pl",
      points: {
        types: ["parcel_locker"],
      },
      map: {
        defaultLocation: [51.507351, -0.127758],
        initialTypes: ["parcel_locker"],
      },
    });
  }, []);

  function handleShowMap() {
    setShowMap(true);
  }

  return (
    <div style={{width:'100%'}}>
      <button type="button" className="order-button" onClick={handleShowMap}>
        Wybierz paczkomat
      </button>
      {point && !showMap && (
        <div className="long-row" style={{justifyContent: 'center'}}>
          <div>
              <p>{point.name}</p>
              <p>{point.address.line1}</p>
              <p>{point.address.line2}</p>
          </div>
        </div>
      )}
      {showMap && <div id="easypack-map"></div>}
    </div>
  );
}

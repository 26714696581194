import React from "react";
// import ReactDOM from 'react-dom';
import { render } from "react-snapshot";
import App from "./App";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const recaptchaKeys = {
  prod: '6Ld5x2ccAAAAANbIUaJpohZz99yTLwCahnRj3xI8',
  dev: '6LcqO2ccAAAAANN2HuwWB3lXb-EztKpU9YfIJE_u'
};

// ReactDOM.render(
render(
  <GoogleReCaptchaProvider reCaptchaKey={recaptchaKeys.dev}>
    <App />
  </GoogleReCaptchaProvider>,
  document.getElementById("root")
);

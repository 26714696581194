import { useEffect, useRef } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";

export default function Renderer3D(props) {
  const canvas = useRef();
  const renderer = new THREE.WebGLRenderer({
    antialias: true,
    preserveDrawingBuffer: true,
  });

  function getLoader() {
    if(props.fileName.toLowerCase().endsWith(".stl")) {
      return new STLLoader();
    } else if (props.fileName.toLowerCase().endsWith(".obj")) {
      return new OBJLoader();
    }
  }

  function getFirstMeshObject(group) {
    return group.children.filter(child => child.type === "Mesh")[0];
  }
  
  useEffect(() => {
    canvas.current.innerHTML = "";
    canvas.current.append(renderer.domElement);

    if (props.file) {
      console.log(props.file);
      const reader = new FileReader();

      reader.onload = (e) => {
        const loader = getLoader();
        const root = loader.parse(e.target.result);

        const material = new THREE.MeshPhongMaterial({
          color: 0xefefef,
          reflectivity: 0,
        });

        let mesh = null;
        if(loader instanceof STLLoader) {
          mesh = new THREE.Mesh(root, material);
        } else if(loader instanceof OBJLoader) {
          mesh = getFirstMeshObject(root);
        }


        mesh.rotation.x = Math.PI * -0.5;
        mesh.castShadow = true;
        mesh.receiveShadow = true;
        scene.add(mesh);

        // set model on the center
        const box = new THREE.Box3().setFromObject(mesh);
        let translationVector = new THREE.Vector3();
        let sizeVector = new THREE.Vector3();

        box.getCenter(translationVector);
        mesh.position.x = -1 * translationVector.x;
        mesh.position.y = 0;
        mesh.position.z = -1 * translationVector.z;

        // set view on the object
        box.getSize(sizeVector);
        controls.target.y = sizeVector.y / 2;
        camera.position.set(sizeVector.x, sizeVector.y, sizeVector.z);
        controls.update();

        canvas.current.classList.add("renderer-visible");

        requestAnimationFrame(render);

        props.setThumbnail(renderer.domElement);
      };
      reader.readAsBinaryString(props.file);
    } else {
      requestAnimationFrame(render);
    }
  }, [props.file, props.width]);

  const fov = 45;
  const aspect = 2; // the canvas default
  const near = 0.1;
  const far = 1000;
  const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
  camera.position.set(20, 20, 20);

  const controls = new OrbitControls(camera, renderer.domElement);
  controls.update();

  const scene = new THREE.Scene();
  scene.background = new THREE.Color("white");

  {
    const light1 = new THREE.PointLight(0xfdf4dc, 1);
    const light2 = new THREE.PointLight(0xfdf4dc, 1);

    light1.position.set(1000, 1000, 1000);
    light2.position.set(-1000, -1000, -1000);

    scene.add(light1);
    scene.add(light2);
  }

  {
    const gridHelper = new THREE.GridHelper(300, 30, "black", 0xefefef);
    scene.add(gridHelper);
  }

  function resizeRendererToDisplaySize(renderer) {
    // console.log(canvas.current.offsetWidth);
    // console.log(canvas.current.offsetHeight);
    const realCanvas = renderer.domElement;
    const width = canvas.current.offsetWidth;
    const height = canvas.current.offsetHeight;
    const needResize =
      realCanvas.width !== width || realCanvas.height !== height;
    if (needResize) {
      renderer.setSize(width, height, false);
    }
    return needResize;
  }

  function render() {
    if (canvas.current) {
      if (resizeRendererToDisplaySize(renderer)) {
        camera.aspect = 1;
        camera.updateProjectionMatrix();
      }

      renderer.render(scene, camera);

      requestAnimationFrame(render);
    }
  }

  return (
    <div
      ref={canvas}
      className="renderer"
      onDragEnter={props.onDragEnter}
      onDragOver={props.onDragOver}
      onDragLeave={props.onDragLeave}
      onDrop={props.onDrop}
    ></div>
  );
}

import { link } from 'fs';

const MaterialTable = (props) => {
    return (
        <section>
            <fieldset className="render-material-table">
                <p><b>{props.name}</b></p>
                <div className="material-row">
                    <div className="material-column">
                        <h2 className="h2-low">Właściowści</h2>
                        <ul>
                            {props.property.map(property => <li className="para-mini">{property}</li>)}
                        </ul>
                    </div>
                    <div className="material-column">
                        <h2 className="h2-low">Zasosowanie</h2>
                        <ul>
                            {props.appilance.map(appilance => <li className="para-mini">{appilance}</li>)}
                        </ul>
                    </div>
                </div>
            </fieldset>
        </section>
    );
}

export default MaterialTable;

    

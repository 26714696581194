import React from "react";
import { withRouter, Link } from "react-router-dom";

function Howwork() {
  return (
    <section>
      <div className="how-work" vocab="https://schema.org/" typeof="Dataset">
        <div>
          <img src="img/measure.png" alt="wycena" />
          <h3 property="name">WYCENA</h3>
          <p property="description">
            Prześlij plik w formacie.stl lub .obj, wybierz parametry, maszyna
            dokona natychmiastowej wyceny Online
          </p>
        </div>
        <div>
          <img src="img/3d-printer.png" alt="wydruk" />
          <h3 property="name">WYDRUK</h3>
          <p property="description">
            Nasz zespół będzie teraz pracować nad Twoim zleceniem
          </p>
        </div>
        <div>
          <img src="img/inside.png" alt="wysyłka" />
          <h3 property="name">WYSYŁKA</h3>
          <p property="description">
            pakujemy gotowy <span property="keywords">wydruk</span> i wysyłamy na wskazany adres.{" "}
          </p>
        </div>
      </div>
      <ul className="foldable-list delivery-list">
        <li>
          <input type="checkbox" id="list-control-checkbox" />
          <label htmlFor="list-control-checkbox" className="checkbox-icon">
            ▼
          </label>
          <div className="li-top">
            <p>SPRAWDŹ TERMINY REALIZACJI oraz FORMY wysyłki</p>
          </div>
          {/* <div className="li-bottom-home-table-column"> */}
          <div className="li-bottom li-bottom-home-table-column">
            <p>Wysyłka</p>
            <img src="img/open-box.png" alt="paczka" />
            <div className="long-row flex-wrap">
              <div className="cell half-width">
                <p className="p-strong">Paczkomat inPost</p>
                <p className="para-mini">
                  zamówienia do kwoty 250zł netto - 9,99zł netto
                </p>
                <p className="para-mini">
                  zamówienia powyżej 250zł netto - <p className="p-strong"> darmowa wysyłka</p>
                </p>
              </div>
              <div className="cell half-width">
                <p className="p-strong">Kurier inPost</p>
                <p className="para-mini">
                  zamówienia do kwoty 400zł netto - 14,99zł netto
                </p>
                <p className="para-mini">
                  zamówienia powyżej 400zł netto - <p className="p-strong"> darmowa wysyłka</p>
                </p>
              </div>
              <div className="cell half-width">
                <p className="p-strong">Odbiór osobisty</p>
                <p className="para-mini">
                  <p className="p-strong">darmowy</p>
                </p>
              </div>
              <div className="cell half-width">
                <p className="p-strong">Dostawa dla klienta biznesowego na terenie Mielca</p>
                <p className="para-mini">
                  <p className="p-strong">darmowa</p>
                </p>
              </div>
            </div>

            <p> Terminy realizacji</p>
            <img src="img/history.png" alt="czas" />
            <div className="long-row flex-center">
              <div className="cell">
                <p className="para-mini">
                  <p className="p-strong">2-4 dni robocze</p> zlecenia małe, bez specyficznych
                  wymagań,{" "}
                </p>
                <p className="para-mini">
                  {" "}
                  <p className="p-strong">5-8 dni</p> zlecenia większe, produkcje małoseryjne,
                </p>
                <p className="para-mini">
                  <p className="p-strong">powyżej 100sztuk</p> termin realizacji ustalany{" "}
                  <p className="p-strong">indywidualnie</p>
                </p>
                <p className="para-mini">
                  Możliwe zlecenie <p className="p-strong"> EXPRESS</p> - poza kolejką po
                  wcześniejszym ustaleniu warunków.{" "}
                </p>
              </div>
            </div>
          </div>
          {/* </div> */}
        </li>
      </ul>
    </section>
  );
}

export default withRouter(Howwork);

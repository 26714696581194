import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import InPostWidget from "./InPostWidget";
import GlobalConfig from "../misc/GlobalConfig";
import { TextWaiter } from "../misc/Waiters";

function Cart(props) {
  // window.scrollTo(0, 0);

  const [orderPriceUnitColorDiscount, setorderPriceUnitColorDiscount] =
    useState([]);
  const [totalOrderPrice, settotalOrderPrice] = useState(0);
  const [modalSee, setmodalSee] = useState(false);
  const [formData, setFormData] = useState({});
  const [done, setDone] = useState(false);
  const [shippingCost, setShippingCost] = useState(0);
  const [shippingType, setshippingType] = useState("");
  const [orderId, setOrderId] = useState("");
  const [color, setColor] = useState([]);
  const [customColorMultiplier, setCustomColorMultiplier] = useState(1)
  const [deliveryPoint, setDeliveryPoint] = useState({
    name: "",
    address: { line1: "", line2: "" },
  });
  const [showCustomColorNote, setShowCustomColorNote] = useState(false);
  const discounts = {
    5: 0.95,
    10: 0.9,
    25: 0.85,
    50: 0.8,
  };

  useEffect(() => {
    fetch(GlobalConfig.backendHost + "color")
      .then((res) => res.json())
      .then((data) => {
        data.rows.forEach(row => {
          if(row._type === "niestandardowy") {
            setCustomColorMultiplier(parseFloat(row._cost));
            row._cost = 1;
          }
        })

        setColor(data.rows);

        setorderPriceUnitColorDiscount(
          props.orderData.map((od) => {
            return {
              price: od.price,
              unit: od.unit,
              color: data.rows[0],
              discount: getUnitDiscount(od.unit),
              customColor: 0,
            };
          })
        );

        const totalPrices = props.orderData.map(
          (od) =>
            od.price * od.unit * data.rows[0]._cost * getUnitDiscount(od.unit)
        );
        settotalOrderPrice(totalPrices.reduce((ptp, ctp) => ptp + ctp, 0));
      });
  }, []);

  function getUnitDiscount(unit) {
    let discount = 1;
    Object.entries(discounts).forEach(([quantity, newDiscount]) => {
      discount = unit > quantity ? newDiscount : discount;
    });
    return discount;
  }

  function setDeliveryPointHandler(point) {
    setDeliveryPoint(point);
  }

  function reCalculateTotals() {
    settotalOrderPrice(
      orderPriceUnitColorDiscount.reduce(
        (popu, copu) =>
          popu +
          copu.price * copu.unit * copu.color._cost * copu.discount +
          copu.customColor,
        0
      )
    );
    // settotalOrderUnit(orderUnit.reduce((pou, cou) => pou + cou, 0));
  }

  function handleUnit(value, index, force = false) {
    const min = 0;
    const max = 3000;

    value = value >= max ? max - 1 : value;
    value = value <= min && force ? min + 1 : value;

    if (force) {
      setorderPriceUnitColorDiscount((prev) => {
        prev[index].unit = value;
        prev[index].discount = getUnitDiscount(value);

        settotalOrderPrice(
          prev.reduce(
            (popu, copu) =>
              popu +
              copu.price * copu.unit * copu.color._cost * copu.discount +
              copu.customColor,
            0
          )
        );
        return [...prev];
      });
    } else {
      setorderPriceUnitColorDiscount((prev) => {
        let newVal = prev;
        if (prev[index].unit + value >= max) {
          newVal = max - 1;
        } else if (prev[index].unit + value <= min) {
          newVal = min + 1;
        } else {
          newVal = prev[index].unit + value;
        }

        prev[index].unit = newVal;
        prev[index].discount = getUnitDiscount(newVal);

        settotalOrderPrice(
          prev.reduce(
            (popu, copu) =>
              popu +
              copu.price * copu.unit * copu.color._cost * copu.discount +
              copu.customColor,
            0
          )
        );
        return [...prev];
      });
    }
  }

  function getCustomColor(price) {
    return Math.max(30, price * customColorMultiplier - price);
  }

  function handleColor(evt) {
    const index = evt.target.options[evt.target.selectedIndex].dataset.index;
    const co = color[evt.target.value];
    setorderPriceUnitColorDiscount((prev) => {
      const customColor =
        co._type != "niestandardowy"
          ? 0
          : getCustomColor(prev[index].price);
      prev[index].color = { ...co };
      prev[index].customColor = customColor;

      settotalOrderPrice(
        prev.reduce(
          (popu, copu) =>
            popu +
            copu.price * copu.unit * copu.color._cost * copu.discount +
            copu.customColor,
          0
        )
      );
      return [...prev];
    });

    setShowCustomColorNote(
      co._type === "niestandardowy" ||
        orderPriceUnitColorDiscount.some(
          (opucd) => opucd.color._type === "niestandardowy"
        )
    );
  }

  function handleSubmit(eve) {
    eve.preventDefault();

    const orderData = props.orderData.map((op, index) => {
      return {
        ...op,
        unit: orderPriceUnitColorDiscount[index].unit,
        color: orderPriceUnitColorDiscount[index].color,
      };
    });

    console.group("Cart");
    console.log(orderData);
    console.groupEnd("Cart");

    const form = eve.target;
    setFormData({
      name: form.querySelector('input[name="given-name"]').value,
      lastName: form.querySelector('input[name="family-name"]').value,
      city: form.querySelector('input[name="address-line2"]').value,
      postCode: form.querySelector('input[name="postal-code"]').value,
      street: form.querySelector('input[name="address-line3"]').value,
      numberHome: form.querySelector('input[name="building-number"]').value,
      phoneNumber: form.querySelector('input[name="tel"]').value,
      email: form.querySelector('input[name="email"]').value,
      remarks: form.querySelector('textarea[name="remarks"]').value,
      faktura: form.querySelector('input[name="faktura"]').checked,
      company: form.querySelector('input[name="organization"]').value,
      nip: form.querySelector('input[name="nip"]').value,
      companyCity: form.querySelector('input[name="organization-city"]').value,
      companyPostCode: form.querySelector(
        'input[name="organization-postal-code"]'
      ).value,
      companyStreet: form.querySelector('input[name="organization-street"]')
        .value,
      totalPrice: Math.round((totalOrderPrice * 1.23 + shippingCost) * 100) / 100.0,
      shipping: shippingType,
      lockerName: deliveryPoint.name,
      lockerAddress: deliveryPoint.address,
      orderData: orderData,
    });

    if(shippingType === "paczkomat" && !deliveryPoint.name) {
      alert("Proszę wybrać paczkomat");
    } else {
      setmodalSee(true);
    }
  }

  function handleEmailSubmit() {
    fetch(GlobalConfig.backendHost + "koszyk", {
      method: "post",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log(data);
        setOrderId(data.orderId);
      });

    console.log("wyslano");
  }

  function removeFromCart(index) {
    if (
      window.confirm(
        `Czy na pewno chcesz usunąć ${props.orderData[index].fileName} z koszyka?`
      )
    ) {
      setColor((prev) => {
        prev.splice(index, 1);
        return [...prev];
      });
      setorderPriceUnitColorDiscount((prev) => {
        prev.splice(index, 1);
        return [...prev];
      });

      props.removeOrderData(index);

      reCalculateTotals();
    }
  }

  return (
    <section className="cart-section">
      <h2 id="cart-header">Twoje zamówienia</h2>
      <fieldset>
        <legend>Koszyk</legend>
        {orderPriceUnitColorDiscount.map((opu, index) => {
          return (
            <div className="cart-row" key={index}>
              <img
                src="img/delete.png"
                className="remove-from-cart"
                onClick={() => removeFromCart(index)}
              ></img>
              <img src={props.orderData[index].thumbnail} alt="" />
              <p>{props.orderData[index].fileName}</p>
              <div className="color">
                <h2 className="h2-low">Wybierz kolor:</h2>
                <select name="color" onChange={handleColor}>
                  {color.map((co, coi) => {
                    return (
                      <option value={coi} data-index={index}>
                        {co._type}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="cart-row-center">
                <p className="units"></p>
                <button
                  className="arrow"
                  onClick={() => {
                    handleUnit(-1, index);
                    reCalculateTotals();
                  }}
                >
                  ➖
                </button>
                {/* <p>{opu.unit}</p> */}
                <input
                  type="number"
                  name="units"
                  value={opu.unit}
                  className="units-count"
                  onChange={(ev) => handleUnit(parseInt(ev.target.value), index, true)}
                />
                <button
                  className="arrow"
                  onClick={() => {
                    handleUnit(1, index);
                    reCalculateTotals();
                  }}
                >
                  ➕
                </button>
              </div>
              <p>
                {Math.round(
                  (opu.price * opu.color._cost * opu.unit * opu.discount +
                    opu.customColor) * 100
                ) / 100.0}{" "}
                zł
              </p>
            </div>
          );
        })}
      </fieldset>
      {showCustomColorNote && (
        <p>
          Przy wyborze koloru niestandardowego czas realizacji może wydłużyć się
          o 2-3 dni.
        </p>
      )}
      {showCustomColorNote && (
        <p>Prosimy o podanie koloru/filamentu w uwagach do zamówienia.</p>
      )}
      <form action="/cart" method="post" onSubmit={handleSubmit}>
        <div className="data-pay">
          <div className="payment-form-all">
            <h2 className="h2-low">Podaj dane do wysyłki:</h2>
            <div className="ind-data">
              <fieldset className="payment-form">
                <legend>Imię</legend>
                <input
                  autoComplete="on"
                  type="text"
                  name="given-name"
                  data-invalid=""
                  required
                />
              </fieldset>
              <fieldset className="payment-form">
                <legend>Nazwisko</legend>
                <input
                  autoComplete="on"
                  type="text"
                  name="family-name"
                  required
                />
              </fieldset>
              <fieldset className="payment-form">
                <legend>Miasto</legend>
                <input
                  autoComplete="on"
                  type="text"
                  name="address-line2"
                  required
                />
              </fieldset>
              <fieldset className="payment-form">
                <legend>Kod pocztowy</legend>
                <input
                  autoComplete="on"
                  type="text"
                  name="postal-code"
                  required
                />
              </fieldset>
              <fieldset className="payment-form">
                <legend>Ulica</legend>
                <input
                  autoComplete="on"
                  type="text"
                  name="address-line3"
                  required
                />
              </fieldset>
              <fieldset className="payment-form">
                <legend>nr. domu/mieszkania</legend>
                <input
                  autoComplete="on"
                  type="text"
                  name="building-number"
                  required
                />
              </fieldset>
              <fieldset className="payment-form">
                <legend>Nr. tel</legend>
                <input autoComplete="on" type="text" name="tel" required />
              </fieldset>
              <fieldset className="payment-form">
                <legend>E-mail</legend>
                <input autoComplete="on" type="email" name="email" required />
              </fieldset>
              <fieldset className="payment-form">
                <legend>Uwagi do zamówienia</legend>
                <textarea
                  className="textarea-cart"
                  name=""
                  id=""
                  name="remarks"
                ></textarea>
              </fieldset>
            </div>
            <input autoComplete="on" type="checkbox" name="faktura" id="" />
            <p>Chcę otrzymać fakturę</p>
            <div className="ind-data ind-data-hidden">
              <fieldset className="payment-form">
                <legend>NIP</legend>
                <input autoComplete="on" type="text" name="nip" />
              </fieldset>
              <fieldset className="payment-form">
                <legend>Nazwa firmy</legend>
                <input autoComplete="on" type="text" name="organization" />
              </fieldset>
              <fieldset className="payment-form">
                <legend>Miasto</legend>
                <input autoComplete="on" type="text" name="organization-city" />
              </fieldset>
              <fieldset className="payment-form">
                <legend>Kod pocztowy</legend>
                <input
                  autoComplete="on"
                  type="text"
                  name="organization-postal-code"
                />
              </fieldset>
              <fieldset className="payment-form">
                <legend>Ulica i nr.</legend>
                <input
                  autoComplete="on"
                  type="text"
                  name="organization-street"
                />
              </fieldset>
            </div>
          </div>
          <div className="delivery">
            <h2 className="h2-low">Wysyłka:</h2>
            <table className="delivery-table">
              <tr>
                <th>Forma</th>
                <th>Cena</th>
                <th> </th>
              </tr>
              <tr>
                <td>
                  <img src="img/inpost.png" alt="" />
                </td>
                <td>
                  <p>Paczkomat</p>
                  {totalOrderPrice < 250 ? <p>9,99 zł</p> : <p>0 zł</p>}
                </td>
                <td>
                  <input
                    type="radio"
                    name="delivery"
                    value="pack"
                    id=""
                    onClick={() => {
                      totalOrderPrice < 250
                        ? setShippingCost(9.99)
                        : setShippingCost(0);
                      setshippingType("paczkomat");
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <img src="img/inpost.png" alt="" />
                </td>
                <td>
                  <p>Kurier</p>
                  {totalOrderPrice < 400 ? <p>14,99 zł</p> : <p>0 zł</p>}
                </td>
                <td>
                  <input
                    type="radio"
                    name="delivery"
                    id=""
                    onClick={() => {
                      totalOrderPrice < 400
                        ? setShippingCost(14.99)
                        : setShippingCost(0);
                      setshippingType("kurier");
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p>Odbiór na miejscu</p>
                </td>
                <td>
                  <p>0 zł</p>
                </td>
                <td>
                  <input
                    type="radio"
                    name="delivery"
                    id=""
                    onClick={() => {
                      setShippingCost(0);
                      setshippingType("odbiór na miejscu");
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p>Dostawa na terenie Mielca dla firm</p>
                </td>
                <td>
                  <p>0 zł</p>
                </td>
                <td>
                  <input
                    type="radio"
                    name="delivery"
                    id=""
                    onClick={() => {
                      setShippingCost(0);
                      setshippingType("Dostawa na terenie Mielca dla firm");
                    }}
                  />
                </td>
              </tr>
            </table>
          </div>
          <table className="vat">
            <tr>
              <td>
                <h4>Cena netto:</h4>
              </td>
              <td>
                <p>{Math.round(totalOrderPrice * 100) / 100.0}</p>
              </td>
            </tr>
            <tr>
              <td>
                <h4>+ Vat:</h4>
              </td>
              <td>
                <p>23%</p>
              </td>
            </tr>
          </table>
          {shippingType === "paczkomat" && (
            <InPostWidget setDeliveryPointHandler={setDeliveryPointHandler} />
          )}
          {/* </div> */}
          <div className="sum">
            <fieldset>
              <legend>Razem</legend>
              <p id="price">
                <b>{Math.round((totalOrderPrice * 1.23 + shippingCost) * 100) / 100.0} zł</b>
              </p>
              <input type="checkbox" name="faktura" id="" required />
              <p className="para-mini">
                Oświadczam, że akceptuję <Link to="/regulamin">regulamin </Link>{" "}
                i <Link to="/polityka_prywatnosci"> Politykę Prywatności</Link>
              </p>
              <button type="submit" id="myBtn" className="order-button">
                Zamów
              </button>
            </fieldset>
          </div>
          {/* <!-- The Modal --> */}
        </div>
      </form>
      <div
        id="myModal"
        className="modal"
        style={{ display: modalSee ? "block" : "none" }}
      >
        {/* <!-- Modal content --> */}
        <div className="modal-content">
          <table>
            <tr>
              <td className="para-mini">
                <b>Imię i nazwisko:</b>
              </td>
              <td>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  data-invalid=""
                  hidden
                  required
                />
                <input
                  type="text"
                  name="last-name"
                  data-invalid=""
                  hidden
                  required
                />
                {formData.name} {formData.lastName}
              </td>
            </tr>
            <tr>
              <td className="para-mini">
                <b>Adres:</b>
              </td>
              <td>
                {formData.postCode} {formData.city} {formData.street}{" "}
                {formData.numberHome}
              </td>
            </tr>
            <tr>
              <td className="para-mini">
                <b>Dane kontaktowe:</b>
              </td>
              <td>
                tel: {formData.phoneNumber} <br />
                email: {formData.email}
              </td>
            </tr>
            <tr>
              <td>{formData.faktura}</td>
              <td></td>
            </tr>
            {formData.faktura && (
              <tr>
                <td className="para-mini">
                  <b>Nazwa firmy:</b>
                </td>
                <td>{formData.company}</td>
              </tr>
            )}
            {formData.faktura && (
              <tr>
                <td className="para-mini">
                  <b>NIP:</b>
                </td>
                <td>{formData.nip}</td>
              </tr>
            )}
            {formData.faktura && (
              <tr>
                <td className="para-mini">
                  <b>Adres:</b>
                </td>
                <td>
                  {formData.companyPostCode} {formData.companyCity}{" "}
                  {formData.companyStreet} {formData.companyNumber}
                </td>
              </tr>
            )}
            <tr>
              <td className="para-mini">
                <b>Uwagi:</b>
              </td>
              <td>{formData.remarks ? formData.remarks : "."}</td>
            </tr>
            <tr>
              <td className="para-mini">
                <b>Typ wysyłki:</b>
              </td>
              <td>{shippingType}</td>
            </tr>
            {shippingType === "paczkomat" && (
              <tr>
                <td className="para-mini">
                  <b>adres paczkomatu:</b>
                </td>
                <td>
                  <p>&nbsp;&nbsp;{deliveryPoint.name}</p>
                  <p>&nbsp;&nbsp;{deliveryPoint.address.line1}</p>
                  <p>&nbsp;&nbsp;{deliveryPoint.address.line2}</p>
                </td>
              </tr>
            )}
            <tr>
              <td className="para-mini">
                <b>Cena za wysyłkę:</b>
              </td>
              <td>{shippingCost}</td>
            </tr>
            <tr>
              <td className="para-mini">
                <b>Cena za całość brutto:</b>
              </td>
              <td> {Math.round((totalOrderPrice * 1.23 + shippingCost) * 100) / 100.0} zł</td>
            </tr>
            {props.orderData.map((od, odi) => {
              return (
                <tr>
                  <td className="para-mini">
                    <b>Właściwości produktu {od.fileName}:</b>
                  </td>
                  <td>
                    <p>nazwa: {od.fileName}</p>
                    <p>
                      Cena za produkt:{" "}
                      {orderPriceUnitColorDiscount[odi]
                        ? orderPriceUnitColorDiscount[odi].price
                        : ""}
                    </p>
                    <p>
                      ilość sztuk:{" "}
                      {orderPriceUnitColorDiscount[odi]
                        ? orderPriceUnitColorDiscount[odi].unit
                        : ""}
                    </p>
                    <p>materiał: {od.material._mat_name}</p>
                    <p>
                      obróbka:{" "}
                      {od.tooling.map((tool) => {
                        return tool._type === "bez obróbki" ? (
                          "bez"
                        ) : (
                          <p>&nbsp;&nbsp;•&nbsp;{tool._type}</p>
                        );
                      })}
                    </p>
                    <p>jakość: {od.quality._type}</p>
                    <p>stopień wypełnienia: {od.fill._type}</p>
                    <p>czas wykonania: {od.time._type}</p>
                    <p>
                      kolor:{" "}
                      {orderPriceUnitColorDiscount[odi]
                        ? orderPriceUnitColorDiscount[odi].color._type
                        : ""}
                    </p>
                  </td>
                </tr>
              );
            })}
            <tr>
              <td className="para-mini">
                <button
                  className="order-button"
                  onClick={() => {
                    setDone(true);
                    setmodalSee(false);
                    handleEmailSubmit();
                  }}
                >
                  Potwierdź
                </button>
              </td>
              <td>
                <button
                  onClick={() => {
                    setmodalSee(false);
                  }}
                  className="order-button"
                >
                  wróć
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div className="modal" style={{ display: done ? "block" : "none" }}>
        <div className="modal-content" style={{ alignItems: "flex-start" }}>
          <h2 className="h2-low">Dziękujemy za zamówienie</h2>
          <p>
            <b>Prosimy o opłatę na nasze konto bankowe:</b>
          </p>
          <p>
            &nbsp;&nbsp;<b>odbiorca:</b> 3D GRAF MILAN HUDELA
          </p>
          <p>
            &nbsp;&nbsp;<b>nr. konta:</b> 90 1050 1445 1000 0092 8791 0609
          </p>
          <p>
            {" "}
            &nbsp;&nbsp;<b>Kod BIC SWIFT:</b> INGBPLPW
          </p>
          <p>
            &nbsp;&nbsp;<b>w tytule nr. zamówienia:</b>{" "}
            {orderId === "" ? <TextWaiter /> : orderId}
          </p>
          <p>
            &nbsp;&nbsp;<b>adres:</b> <br />
            &nbsp;&nbsp;&nbsp;&nbsp;Warneńczyka 6/70 <br />
            &nbsp;&nbsp;&nbsp;&nbsp;39-300 Mielec
          </p>
          <p>W wiadmości mailowej dostaną Państwo potwierdzenie zamówienia</p>
          <Link to="/">
            <button className="order-button" onClick={() => window.location.replace("/")}>wróć na stronę głowną</button>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default withRouter(Cart);

const sliderContent = [
    {
        img: "img/handshake.png",
        text: "Pomoc i doradztwo",
    },
    {
        img: "img/time.png",
        text: "Wysoka jakość"
    },
    {
        img: "img/chess.png",
        text: "Szybki czas wykonania"
        
    }
];

export default sliderContent;

import React from "react";

export default function MielecInfo() {
  return (
    <section>
      <div
        className="mielec-info"
        vocab="https://schema.org/"
        typeof="LocalBusiness"
      >
        <img src="img/herb.png" alt="herb Mielec" />
        <div>
          <h3 className="mielec-slogan">
            Wspieramy lokalnych przedsiębiorców oraz instytucje
          </h3>
          <p typeof="LocalBusiness">
            Na terenie Naszego miasta <strong> Mielca</strong> i okolic w przypadku pilnych
            realizacji dla działów utrzymania ruchu oraz na produkcję oferujemy
            wykonanie zlecenia w trybie natychmiastowym oraz natychmiastowe
            dostarczenie do siedziby firmy. Masz częstą potrzebę wykonania
            zlecenia w trybie ekspresowym? Awaria maszyny i brak części
            zamiennej ? Przerwa w ciągłości produkcji z powodu braku narzędzia?
            - Dobrze trafiłeś, Naszą misją jest wspieranie lokalnych firm, warto
            zatem rozpocząć z nami WSPÓŁPRACĘ.
          </p>
        </div>
      </div>
      <div className="photo-text" vocab="https://schema.org/" typeof="WebSite">
        <div className="filtr">
          <h4 className="h1">
            Do każdego zlecenia podchodzimy z pasją i zaangażowaniem, każdy klient jest dla nas bardzo ważny. Dbamy o pełną satysfakcję Naszego klienta
          </h4>
        </div>
      </div>
    </section>
  );
}

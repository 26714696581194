import React from 'react'
import { withRouter, Link } from 'react-router-dom'


function Hero() {
    return (
        <section className="hero" vocab="https://schema.org/" typeof="WebSite">
           <h1 property="headline"><span property="keyword">Wydruk 3D</span> najlepszej klasy</h1>
           <button className="hero-button"><Link className="button-link" to="/wycena">SZYBKA WYCENA</Link></button>
        </section>
    )
}

export default withRouter(Hero);

import { withRouter, Link } from "react-router-dom";
import { useState } from "react";

function Navbar() {
  const [burgerActive, setBurgerActive] = useState(false);

  const handleClick = (e) => {
    e.stopPropagation();
    setBurgerActive((prevState) => {
      return !prevState;
    });
  };

  return (
    <div>
      <div
        className="navbar"
        vocab="https://schema.org/"
        typeof="BreadcrumbList"
      >
        <div className="nav-logo">
          <Link className="nav-link" to="/">
            <img src="img/logo2.png" alt="3dgraf" />
          </Link>
        </div>
        <div
          className={"nav-links" + (burgerActive ? " nav-active" : "")}
          property="itemListElement"
          typeof="ListItem"
        >
          <Link
            onClick={handleClick}
            className="nav-link"
            to="/"
            property="item"
            typeof="WebPage"
          >
            <span property="name">Strona głowna</span>
          </Link>
          <Link
            onClick={handleClick}
            className="nav-link"
            to="/oferta"
            property="item"
            typeof="WebPage"
          >
            <span property="name">Oferta</span>
          </Link>
          <Link
            onClick={handleClick}
            className="nav-link"
            to="/wycena"
            property="item"
            typeof="WebPage"
          >
            <span property="name">Szybka wycena</span>
          </Link>
          <Link
            onClick={handleClick}
            className="nav-link"
            to="/realizacje"
            property="item"
            typeof="WebPage"
          >
            <span property="name"> Realizacje</span>
          </Link>
          <Link
            onClick={handleClick}
            className="nav-link"
            to="/kontakt"
            property="item"
            typeof="WebPage"
          >
            <span property="name">Kontakt</span>
          </Link>
          <Link
            onClick={handleClick}
            className="nav-link cart-burger"
            to="/koszyk"
            property="item"
            typeof="WebPage"
          >
            <span property="name">Twoje Zamówienia</span>
            {/* <img src="img/shopping-basket(1).png" alt="koszyk" /> */}
          </Link>
          <meta property="position" content="1" />
        </div>
        <Link
          onClick={handleClick}
          className="nav-link cart"
          to="/koszyk"
          property="item"
          typeof="WebPage"
        >
          <span property="name">Twoje Zamówienia</span>
          <img src="img/shopping-basket(1).png" alt="koszyk" />
        </Link>
      </div>
      <div onClick={handleClick} className="burger">
        <img src="img/menu.svg" alt="menu" />
        
      </div>
    </div>
  );
}

export default withRouter(Navbar);

import React, { useCallback, useEffect, useState } from "react";
// import ReCAPTCHA from "react-google-recaptcha";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import GlobalConfig from "../misc/GlobalConfig";

export default function Contact(props) {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(() => {
    if (!executeRecaptcha) {
      return new Promise((reject) => "Execute recaptcha not yet available");
    }

    return executeRecaptcha("contact");
  }, []);

  const [popup, setpopup] = useState(false);
  const [showWaiter, setShowWaiter] = useState(false);

  function handleSubmit(eve) {
    eve.preventDefault();
    setShowWaiter(true);

    handleReCaptchaVerify()
      .then((token) => {
        console.log("ReCaptcha token:", token);

        const form = eve.target;
        const formData = {
          name: form.querySelector('input[name="name"]').value,
          mail: form.querySelector('input[name="mail"]').value,
          message: form.querySelector('textarea[name="message"]').value,
          attachment: props.fileAttachment,
          token: token,
        };

        return fetch(GlobalConfig.backendHost+"kontakt", {
          method: "post",
          body: JSON.stringify(formData),
          headers: {
            "Content-Type": "application/json",
          },
        });
      })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((data) => {
        console.log(data);
        setShowWaiter(false);
        setpopup(true);
        setTimeout(() => setpopup(false), 1500);
      })
      .catch((err) => {
        console.error(err);
        setShowWaiter(false);
        alert("Coś poszło nie tak!");
      });
  }
  // const recaptchaRef = React.createRef();

  return (
    <section className="contact">
      <h2>Masz pytania?</h2>
      <p>Skontaktuj się</p>
      <div className="contact-form-all">
        <div>
          <img src="img/hummingbird.png" alt="" />
          <div className="contact-info">
            <p>
              <strong>Nasze dane kontaktowe</strong>
            </p>
            <p>3D GRAF Milan Hudela</p>
            <p>Warneńczyka 6/70</p>
            <p>39-300 Mielec</p>
            <p>NIP: 817 220 15 79</p>
            <p>REGON: 389602944</p>
            <p>tel: 508 788 260</p>
            <p>email: kontakt@3dgrafmielec.pl</p>
            <p>Dane do płatności:</p>
            <p> ING Bank Śląski S.A.</p>
            <p> PL 90 1050 1445 1000 0092 8791 0609</p>
          </div>
        </div>
        <div>
          <form
            className="contact-form"
            action=""
            onSubmit={
              handleSubmit
              // , () => recaptchaRef.current.execute()
            }
          >
            {props.fileAttachment.name != "" && <h3>Załączono plik {props.fileAttachment.name}</h3>}
            <fieldset>
              <legend>imię</legend>
              <input type="text" name="name" />
            </fieldset>
            <fieldset>
              <legend>e-mail</legend>
              <input type="text" name="mail" />
            </fieldset>
            <fieldset>
              <legend>wiadomość</legend>
              <textarea id="" cols="30" rows="10" name="message"></textarea>
            </fieldset>
            <div>
              <button className="contact-button">
                WYŚLIJ
                <div
                  className="waiter"
                  style={{ display: showWaiter ? "block" : "none" }}
                ></div>
              </button>
              <span
                className={"popuptext" + (popup === true ? " show" : "")}
                id="myPopup"
              >
                Wysłano pomyślnie
              </span>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

import { HashLink } from "react-router-hash-link";
import { withRouter, Link } from "react-router-dom";
import calculateGeometryParameters from "./GeometryCalculator";
import GlobalConfig from "../misc/GlobalConfig";
import React, { useState, useEffect } from "react";
import Renderer3D from "./Renderer3D";
import Tooling from "./Tooling";
import Unit from "./Unit";

function Pricing(props) {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [fileDataString, setFileDataString] = useState(0);
  const [fileThumbnail, setFileThumbnail] = useState(0);
  const [done, setDone] = useState(false);
  const [ftlFallback, setFtlFallback] = useState(false);

  // staty do aktualizowania ceny końcowej
  const [dataObject, setDataObject] = useState({}); // informacje o modelu
  const [unit, setUnit] = useState(1); // ilość sztuk
  const [materialSelected, setMaterialSelected] = useState(null); // wybrany materiał
  const [toolingSelected, setToolingSelected] = useState([]); // wykończenie (ładowane z bazy danych?)
  const [qualitySelected, setQualitySelected] = useState(null); // jakość (ładowana z bazy danych?)
  const [fillSelected, setFillSelected] = useState(null); // wypełnienie 'fill' (ładowane z bazy danych?)
  const [timeSelected, setTimeSelected] = useState(null); // czas pracy (ładowana z bazy danych?)
  const [materialMass, setmaterialMass] = useState(0);
  const [totalPrice, settotalPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [discounts, setDiscounts] = useState({
    5: 0.95,
    10: 0.9,
    25: 0.85,
    50: 0.8,
  });

  const [materials, setMaterials] = useState([]);
  const [pricing, setPricing] = useState([]);
  const [tooling, setTooling] = useState([]);
  const [quality, setQuality] = useState([]);
  const [fill, setFill] = useState([]);
  const [time, setTime] = useState([]);
  const [unitMeter, setunitMeter] = useState("mm");

  useEffect(() => {
    fetch(GlobalConfig.backendHost + "materials") // MATERIALS
      .then((res) => res.json())
      .then((data) => {
        let materialsNames = [];
        let result = [];
        data.rows.forEach((row) => {
          if (!materialsNames.includes(row._material_name)) {
            materialsNames.push(row._material_name);
          }
        });
        materialsNames.forEach((materialName) => {
          const properties = data.rows
            .filter((row) => row._material_name === materialName)
            .map((row) => row._property)
            .filter((property) => property)
            .slice(0, 3)
            .join(", ");

          result.push({
            name: materialName,
            properties: properties,
          });
        });
        setMaterials(result);

        return fetch(GlobalConfig.backendHost + "pricing");
      })
      .then((res) => res.json())
      .then((data) => {
        // PRICING
        setPricing(data.rows);
        setMaterialSelected(data.rows[0]);

        return fetch(GlobalConfig.backendHost + "tooling");
      })
      .then((res) => res.json())
      .then((data) => {
        // TOOLING
        setTooling(data.rows);

        return fetch(GlobalConfig.backendHost + "quality");
      })
      .then((res) => res.json())
      .then((data) => {
        // FILL
        setQuality(data.rows);
        setQualitySelected(data.rows[0]);

        return fetch(GlobalConfig.backendHost + "fill");
      })
      .then((res) => res.json())
      .then((data) => {
        // TIME
        setFill(data.rows);
        setFillSelected(data.rows[0]);

        return fetch(GlobalConfig.backendHost + "jobTime");
      })
      .then((res) => res.json())
      .then((data) => {
        setTime(data.rows);
        setTimeSelected(data.rows[0]);
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    calculateGeometryParameters(uploadedFile, uploadedFileName, unitMeter).then(
      (data) => {
        if ([data.x, data.y, data.z].some((dim) => dim > 250)) {
          modelToLargeFallback(1);
          return;
        }

        setDataObject(data);

        if (unit >= 1000) setUnit(999);
        let discount = 1;
        Object.entries(discounts).forEach(([quantity, newDiscount]) => {
          discount = unit > quantity ? newDiscount : discount;
        });

        if (uploadedFile != null) {
          console.log("mass:", data);
          const mm =
            parseFloat(materialSelected._density) * data.volume * 0.001;
          setmaterialMass(mm);
          let price = mm * materialSelected._mass;
          price += data.boxVolume * materialSelected._volume_box * 0.001;
          console.log("price: ", price);
          settotalPrice(
            Math.round(
              (((price + parseFloat(materialSelected._additional)) *
                parseFloat(qualitySelected._cost) *
                parseFloat(fillSelected._cost) *
                parseFloat(timeSelected._cost) +
                toolingSelected.reduce((sum, tool) => {
                  return (sum += parseFloat(tool._cost));
                }, 0)) *
                unit *
                discount) * 100
            ) / 100.0
          );
          console.log(totalPrice);
        }
      }
    );
  }, [
    uploadedFile,
    materialSelected,
    toolingSelected,
    qualitySelected,
    fillSelected,
    unit,
    discount,
    timeSelected,
    unitMeter,
  ]);

  function modelToLargeFallback(mode) {
    if (mode === 1) {
      setFtlFallback(true);
    } else if (mode === 2) {
      props.setFileAttachment({
        name: uploadedFileName,
        file: fileDataString,
      });
      setFtlFallback(false);
    } else if (mode === 3) {
      setUploadedFileName("");
      setUploadedFile(null);
      setFileDataString(0);
      setFtlFallback(false);
    }
  }

  function preventDefaultStopPropagation(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  function handleFile(file) {
    setUploadedFile(file);
    setUploadedFileName(file.name);

    const reader = new FileReader();
    reader.onload = (ev) => setFileDataString(ev.target.result);
    reader.readAsDataURL(file);
  }

  function dropHandler(ev) {
    // preventDefaultStopPropagation(ev);

    let dt = ev.dataTransfer;
    ev.target.files = dt.files;
    fileInputHandler(ev);
    // let files = dt.files;

    // if (files.length > 0) {
    //   handleFile(files[0]);
    // }
  }

  function fileInputHandler(ev) {
    preventDefaultStopPropagation(ev);

    let files = ev.target.files;

    if (files.length > 0) {
      handleFile(files[0]);
    }
  }

  function handleUnit(value, force = false) {
    value = value >= 3000 ? 2999 : value;
    value = value <= 0 && force ? 1 : value;

    if (force) {
      setUnit(value);
    } else {
      setUnit((prev) => {
        if (prev + value >= 3000) return 2999;
        if (prev + value <= 0) return 1;
        return prev + value;
      });
    }
  }

  // funkcja do obliczania masy materiału wywoływana w: dropHandler, fileInputHandler i handleMaterial

  function handleMaterial(ev) {
    setMaterialSelected(
      pricing.filter((mat) => mat._mat_name === ev.target.value)[0]
    );
  }

  function handleQuality(ev) {
    setQualitySelected(
      quality.filter((qua) => qua._type === ev.target.value)[0]
    );
  }

  function handleFill(ev) {
    setFillSelected(fill.filter((f) => f._type === ev.target.value)[0]);
  }

  function handleJobTime(ev) {
    setTimeSelected(
      time.filter((jobtime) => jobtime._type === ev.target.value)[0]
    );
  }

  function addToCart() {
    if (!fileDataString) {
      return;
    }

    const toCart = {
      material: materialSelected,
      tooling: toolingSelected,
      quality: qualitySelected,
      fill: fillSelected,
      time: timeSelected,
      price: totalPrice / unit,
      unit: unit,
      file: fileDataString,
      fileName: uploadedFileName,
      thumbnail: fileThumbnail.toDataURL("image/png"),
    };

    console.group("Pricing");
    console.log(toCart);
    console.groupEnd("Pricing");

    props.handleOrderData(toCart);
    setDone(true);
  }

  return (
    <section
      className="pricing-column"
      vocab="https://schema.org/"
      typeof="Dataset"
      id="pricing"
    >
      <h2 property="headline" typeof="Text">
        Wycena
      </h2>
      <p property="description">
        Prześlij pliki w formacie .stl lub .obj w celu dokonania darmowej
        wyceny. Finalny koszt wydruku zależy od kilku kluczowych parametrów,
        które należy wybrać. Są to: materiał, stopień wypełnienia wewnętrznego,
        jakość, rodzaj obróbki dodatkowej. W poniższej tabeli poznacie
        podstawowe właściwości popularnych tworzyw
      </p>

      <ul className="foldable-list">
        <li>
          <input type="checkbox" id="list-control-checkbox" />
          <label htmlFor="list-control-checkbox" className="checkbox-icon">
            ▼
          </label>
          <div className="li-top">
            <p>Lista podstawowych właściwości tworzyw</p>
          </div>
          <div className="li-bottom">
            <ul>
              {materials.map((material) => {
                return (
                  <li className="para-mini">
                    <span property="keywors">
                      <b>{material.name} - </b>
                    </span>{" "}
                    {material.properties}
                  </li>
                );
              })}
            </ul>
          </div>
        </li>
      </ul>
      <p className="color-info">
        <strong>Kolor</strong> przy wyborze w zakładce Twoje zamówienie,
        domyślnymi kolorami są <strong>czarny i grafitowy</strong>, jeżeli
        jesteście zainteresowani innymi kolorami, prosimy o wiadomość prywatną.
      </p>
      <div className="pricing-row">
        <div className="files">
          <div className="file-add-div">
            <fieldset className="add-file">
              <legend>Podgląd</legend>
              <Renderer3D
                file={uploadedFile}
                fileName={uploadedFileName}
                width={300}
                setThumbnail={(thumbnail) => setFileThumbnail(thumbnail)}
                onDragEnter={preventDefaultStopPropagation}
                onDragOver={preventDefaultStopPropagation}
                onDragLeave={preventDefaultStopPropagation}
                onDrop={dropHandler}
              />
              <div className="on-drop-info">
                <img src="img/upload.png" alt="" />
                <p className="units">przeciągnij pliki tutaj</p>
              </div>
            </fieldset>
            <input type="file" id="file" onChange={fileInputHandler} />
            <label htmlFor="file" className="btn-2">
              Wgraj lub przenieś pliki
            </label>
          </div>
        </div>
        <div className="parameters">
          <Unit setUnit={setunitMeter} />
          <div className="row long-row pricing-and">
            <fieldset className="select">
              <legend>Wybierz materiał</legend>
              <select name="material" onChange={handleMaterial}>
                {pricing.map((mat) => {
                  return <option value={mat._mat_name}>{mat._mat_name}</option>;
                })}
              </select>
            </fieldset>
            <HashLink to="/oferta#materials-list" className="simple-link">
              Szczegółowe właściwości tworzyw
            </HashLink>
          </div>
          <Tooling setTooling={setToolingSelected} tooling={tooling} />
          <fieldset className="select">
            <legend>Jakość</legend>
            <select name="quality" onChange={handleQuality}>
              {quality.map((qua) => {
                return (
                  <option
                    value={qua._type}
                    selected={qua._type.includes("STANDARD") ? "selected" : ""}
                  >
                    {qua._type}
                  </option>
                );
              })}
            </select>
          </fieldset>
          <fieldset className="select">
            <legend>Stopień wypełnienia *</legend>
            <select name="fill" onChange={handleFill}>
              {fill.map((f) => {
                return <option value={f._type}>{f._type}</option>;
              })}
            </select>
          </fieldset>
          <fieldset className="select">
            <legend>Czas wykonania</legend>
            <select name="timeSelected" onChange={handleJobTime}>
              {time.map((job) => {
                return <option value={job._type}>{job._type}</option>;
              })}
            </select>
          </fieldset>
          <div className="long-row2">
            <div className="pricing-and">
              <fieldset className="select">
                <legend className="units">ilość sztuk **</legend>
                <button className="arrow" onClick={() => handleUnit(-1)}>
                  ➖
                </button>
                <input
                  type="number"
                  name="units"
                  value={unit}
                  className="units-count"
                  onChange={(ev) => handleUnit(parseInt(ev.target.value), true)}
                />
                <button className="arrow" onClick={() => handleUnit(1)}>
                  ➕
                </button>
              </fieldset>

              <table className="sale-table">
                <tr className="th-underline">
                  <th className="para-mini">Ilość sztuk</th>
                  <th className="para-mini">Rabat</th>
                </tr>
                <tr>
                  <td className="para-mini">5 do 9</td>
                  <td className="para-mini">5%</td>
                </tr>
                <tr>
                  <td className="para-mini">10 do 24</td>
                  <td className="para-mini">10%</td>
                </tr>
                <tr>
                  <td className="para-mini">25 do 49</td>
                  <td className="para-mini">15%</td>
                </tr>
                <tr>
                  <td className="para-mini">50 do 99</td>
                  <td className="para-mini">20%</td>
                </tr>
                <tr>
                  <td className="para-mini">od 100</td>
                  <td className="para-mini">specjalny</td>
                </tr>
              </table>
            </div>
          </div>
          <div>
            <fieldset className="total-price-box">
              <legend>cena</legend>
              <p className="total-price">{totalPrice ? totalPrice : 0}</p>
            </fieldset>
            <button className="order-button" onClick={addToCart}>
              zamów
            </button>
          </div>
        </div>
      </div>
      <p className="para-mini prewrap">
        {`* standardowe wypełnienie to 30%, zapewnia już ono znaczącą wytrzymałość wydruku, w przypadku wartości wypełnienia spoza zakresu, prosimy wpisać w uwagach do zamówienia żądaną wartość.`}
      </p>
      <div className="specification">
        <fieldset className="specification">
          <legend> Specyfikacja wgranego pliku</legend>
          <table className="specify-table">
            <tr>
              <th>Objętość produktu:</th>
              <td>
                {dataObject.volume ? Math.round(dataObject.volume * 0.001) : 0}{" "}
                cm<sup>3</sup>
              </td>
            </tr>
            <tr>
              <th>Wymiary:</th>
              <td>
                {dataObject.x ? Math.round(dataObject.x) : 0} x{" "}
                {dataObject.y ? Math.round(dataObject.y) : 0} x{" "}
                {dataObject.z ? Math.round(dataObject.z) : 0} mm
              </td>
            </tr>
            <tr>
              <th>Waga przy wypełnieniu 100%:</th>
              <td>{Math.round(materialMass)} g</td>
            </tr>
          </table>
        </fieldset>
      </div>
      <div className="modal" style={{ display: done ? "block" : "none" }}>
        <div className="modal-content">
          <h2 className="h2-low">Dodano do zamówienia</h2>
          {/* <Link to="/koszyk">
            <button className="order-button">Przejdź do Twoich zamówień</button>
          </Link> */}
          <HashLink className="link-to-price" to="/koszyk#cart-header">  <button className="order-button">Przejdź do Twoich zamówień</button> </HashLink>
          <button className="order-button" onClick={() => setDone(false)}>
            Dodaj kolejny produkt
          </button>
        </div>
      </div>
      <div
        className="modal"
        style={{ display: ftlFallback ? "block" : "none" }}
      >
        <div className="modal-content">
          <h2 className="h2-low">
            Jeden z wymiarów modelu przekracza 250mm. Czy chcesz wysłać ten plik
            jako zapytanie email w celu indywidualnej wyceny?
          </h2>
          <Link to="/kontakt">
            <button
              className="order-button"
              onClick={() => modelToLargeFallback(2)}
            >
              Tak
            </button>
          </Link>
          <button
            className="order-button"
            onClick={() => modelToLargeFallback(3)}
          >
            Nie
          </button>
        </div>
      </div>
    </section>
  );
}

export default withRouter(Pricing);

import React, { useEffect, useState } from "react";
import sliderContent from "./sliderContent";

export default function WhatDo() {
  const [visibility, setVisibility] = useState(0);

  function sliderInterval() {
    setVisibility((prev) => {
      return prev >= sliderContent.length - 1 ? 0 : prev + 1;
    });
  }

  useEffect(() => {
    setInterval(sliderInterval, 2000);
  }, []);

  return (
    <div className="what-do">
      <div className="list-what-do">
        <p>
          {" "}
          <img className="check-mark" src="img/diamond.png" alt="diament" /><strong> Projektowanie 3D</strong> na podstawie dostarczonych modeli  lub wytycznych  
        </p>
        <p>
          {" "}
          <img className="check-mark" src="img/diamond.png" alt="diament" /> Inżynieria odwrotna: odtwarzanie geometrii zużytych części, projektowanie na podstawie dostarczonych, uszkodzonych elementów . 
        </p>
        <p>
          {" "}
          <img className="check-mark" src="img/diamond.png" alt="diament" /> Wykonywanie wszelkich prototypów, oprzyrządowania linii produkcyjnych, przyrządów, sprawdzianów montażowych, części maszyn, wzorników.
        </p>
        <p>
          {" "}
          <img className="check-mark" src="img/diamond.png" alt="diament" /> Wykonaj <strong>EFEKTOWNE LOGO SWOJEJ FIRMY</strong> w 3D lub gadżety reklamowe. 
        </p>
        <p>
          {" "}
          <img className="check-mark" src="img/diamond.png" alt="diament" /> Druk 3D modeli dostarczonych przez klienta lub projektowanych przez Nas.
        </p>
        <p>
          {" "}
          <img className="check-mark" src="img/diamond.png" alt="diament" /> Druk w technologii SLA/DLP oraz FDM/FFF z 95% dostępnych na rynku tworzyw w tym ESD, na specjalne zamówienie sprowadzimy każde tworzywo.
        </p>
        <p>
          {" "}
          <img className="check-mark" src="img/diamond.png" alt="diament" /> Jakość wydruków na poziomie przemysłowym, wiemy co jest prawidłowym wydrukiem - warto zaufać Naszemu wieloletniemu doświadczeniu.
        </p>
        <p>
          {" "}
          <img className="check-mark" src="img/diamond.png" alt="diament" /> Specjalizujemy się w produkcji małoseryjnej, gdzie zlecenie na detal wynosi niekiedy <strong>kilka tysięcy sztuk</strong>, gdzie koszt zakupu formy wtryskowej jest ekonomicznie nieuzasadniony.   
        </p>
        <p>
          {" "}
          <img className="check-mark" src="img/diamond.png" alt="diament" /> Szybkie terminy realizacji, możliwe wykonanie zlecenia bez kolejki, w przypadku nagłej potrzeby, reagujemy natychmiastowym podjęciem zlecenia. 
        </p>
        <p>
          {" "}
          <img className="check-mark" src="img/diamond.png" alt="diament" /> Doradztwo pod kątem projektowania modelu pod druk 3D lub doborze tworzywa do wydruku 3D
        </p>
      </div>
      <div className="slider-what-do">
        {sliderContent.map((props, index) => {
          return (
            <div
              className="slider"
              style={{ opacity: visibility === index ? "1" : "0" }}
            >
              <img src={props.img} alt={props.text} />
              <h3>{props.text}</h3>
            </div>
          );
        })}
      </div>
    </div>
  );
}

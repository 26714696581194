import React, { useEffect, useState } from "react";

export function TextWaiter() {
  const [opacity, setOpacity] = useState(0.9);

  useEffect(() => {
    setInterval(() => {
      setOpacity((prev) => {
        return prev === 0.9 ? 0.1 : 0.9;
      });
    }, 1000);
  }, []);

  return (
    <span
      style={{
        display: "inline-block",
        width: "100px",
        height: "1em",
        borderRadius: "100vw",
        backgroundColor: "black",
        opacity: opacity,
        transition: "0.9s",
      }}
    ></span>
  );
}

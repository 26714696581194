import React from 'react';

const Cost = () => {
    return (
        <div>
            <h2>Koszt i typy wysyłki</h2>
            <p>Koszt wysyłki do paczkomatu inPost: 9.99  zamówienia do kwoty 250zł netto, powyżej wysyłka darmowa</p>
            <p>Koszt wysyłki kurier inPost: 14.99  zamówienia do kwoty 400zł netto, powyżej wysyłka darmowa</p>
            <p>Odbiór osobisty- darmowy</p>
            <p>Dostawa dla klienta biznesowego na terenie Mielca- darmowa</p>
        </div>
    );
}

export default Cost;

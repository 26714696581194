import React from "react";
import { withRouter, Link } from "react-router-dom";

function Footer() {
  var data = new Date(); 

  return (
    <footer>
      <div className="footer">
        <div className="nav-footer">
          <Link className="nav-link-footer" to="/">
            Strona głowna
          </Link>
          <Link className="nav-link-footer" to="/oferta">
            Oferta
          </Link>
          <Link className="nav-link-footer" to="/wycena">
            Wycena{" "}
          </Link>
          <Link className="nav-link-footer" to="/realizacje">
            Realizacje{" "}
          </Link>
          <Link className="nav-link-footer" to="/kontakt">
            Kontakt{" "}
          </Link>
          <Link className="nav-link-footer" to="/koszyk">
            Koszyk{" "}
          </Link>
        </div>
        <div className="verticalDivider"></div>
        <div className="info-footer">
          <Link to="/koszt_wysylki" className="nav-link-footer">
            Koszt wysyki
          </Link>
          <Link to="/metody_dostawy" className="nav-link-footer">
            Formy dostawy
          </Link>
          <Link to="/polityka_prywatnosci" className="nav-link-footer">
            Polityka prywatności
          </Link>
          <Link to="/regulamin" className="nav-link-footer">
            Regulamin Sklepu
          </Link>
        </div>
        <div className="verticalDivider"></div>
        <div className="logo-footer">
          <img src="img/logo.png" alt="3d Graf" />
          <p>Władysława Warneńczyka 6/70, Mielec</p>
          <a href="">kontakt@3dgrafmielec.pl</a>
          <p>
            <a href="tel:508788260">508 788 260</a>
          </p>
        </div>
      </div>
      <p className="copy">&copy;{data.getFullYear()} by <a href="http://www.web-hunter.pl/">Web hunter</a></p> 
    </footer>
  );
}

export default withRouter(Footer);

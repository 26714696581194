const materialContent = [
    {
        name: "PLA Heavy Duty",
        property: ["duża dokładność wydruku z możliowścią wzmocnienia udarności i odporności na wysokie temperatury (właściowści podobne do ABS)"],
        appilance: [ "elementy maszyn, pojazdów", "skomplikowane elementy o wymagajacych własnościach", "obudowy na telefony", "figurki postaci"]
    },
    {
        name: "PET-G",
        property: ["wytrzymały mechanicznie", "eleastyczny", "odporny na temp. do 80-85°C", "odporność na kwasy, sole, substancje alkaliczne"],
        appilance: ["elementy dekoracyjne", "obudowy dla podzespołów elektronicznych", "pojemniki, opakowania"]
    },
    {
        name: "ABS",
        property: ["duża twardość", "bardzo dobre właściwości termoizolacyjne", "nie odkształca się przy zmianie temperatur"],
        appilance: ["obudowy sprzętów elektonicznych", "elementy motoryzacyjnych (np. kratownice, nadkola)"]
    },
    {
        name: "Nylon PA12",
        property: ["bardzo elastyczny", "trudno ścieralny", "wysoka udarność", "odporności na temperaturę, alkohole i substancje chemiczne"],
        appilance: ["części takie jak: prowadnice", "koła zębate", "łożyska", "nakrętki"]
    },
    {
        name: "Nylon PA12+CF15%",
        property: ["wysoka odporoność chemiczna", "niższa masa przy zachowaniu wysokokiej sztywności"],
        appilance: ["zaawansowane części do samochodów, dronów", "zamienniki elementów metalowych", "prototypy oraz finalne produkty"]
    },
    {
        name: "PP",
        property: ["odporność chemiczna", "wysoka rozszerzalność termiczna", "wysoka wytrzymałość na rozciąganie"],
        appilance: ["części samochodowe: zderzaki, odbojniki, osłonki", "pojemniki na żywność, naczynia", "przedmioty codziennego użytku: pudełka, uchwyty, zapięcia"]
    },
    {
        name: "Guma Flex",
        property: ["bardzo elastyczna", "odporna na chemikalia", "odporna na uderzenia"],
        appilance: ["elastyczne zawiasy", "uszczelki, elementy wymagające częstego wyginania", "prototypy opon", "obudowy telefonów"]
    },
    {
        name: "Żywica UV Tough",
        property: ["Wysoka sztywność", "wysoka wytrzymałość na zginanie ", "doskonała odporność chemiczna"],
        appilance: ["przemysł motoryzacyjny", "prototypy funkcjonalne"]
    },
]

export default materialContent;
import React from 'react'


export default function Delivery() {
    return (
        <section className="delivery-section">
            <h2>Metody dostawy</h2>
            <p>Obecnie realizujemy wysyłkę za pośrednictwem inPost (paczkomat i kurier), wkrótce planujemy rozszerzyć możliwości dostaw.</p>
        </section>
    )
}

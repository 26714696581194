import "./style.css";
import { useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import Pricing from "./components/Pricing/Pricing";
import Galery from "./components/Gallery/Galery";
import Contact from "./components/Contact/Contact";
import Cart from "./components/Cart/Cart";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./components/Home/Home";
import Offert from "./components/Offert/Offert";
import Delivery from "./components/SubSite/Delivery";
import Cost from "./components/SubSite/Cost";
import Regulamin from "./components/SubSite/Regulamin";
import Privacy from "./components/SubSite/Privacy";

function App() {
  const [orderData, setorderData] = useState([]);

  function addOrderData(order) {
    setorderData((prev) => {
      return [...prev, order];
    });
  }

  function removeOrderData(index) {
    setorderData((prev) => {
      prev.splice(index, 1);
      return [...prev];
    });
  }

  const [fileAttachment, setFileAttachment] = useState({ name: "", file: "" });

  return (
    <BrowserRouter>
      <div className="App">
        <Navbar />
        <Route exact path="/" component={Home} />
        <Route path="/oferta/" component={Offert} />
        <Route
          path="/wycena/"
          render={() => (
            <Pricing
              handleOrderData={addOrderData}
              setFileAttachment={setFileAttachment}
            />
          )}
        />
        <Route path="/realizacje/" component={Galery} />
        <Route
          path="/kontakt/"
          render={() => <Contact fileAttachment={fileAttachment} />}
        />
        <Route
          path="/koszyk/"
          render={() => (
            <Cart orderData={orderData} removeOrderData={removeOrderData} />
          )}
        />
        <Route path="/metody_dostawy/" component={Delivery} />
        <Route path="/koszt_wysylki/" component={Cost} />
        <Route path="/regulamin/" component={Regulamin} />
        <Route path="/polityka_prywatnosci/" component={Privacy} />
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;

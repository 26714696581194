import React from 'react'
import Hero from './Hero'
import Howwork from './Howwork'
import Elements from './Elements'
import MielecInfo from './MielecInfo'

export default function Home() {
window.scrollTo(0, 0);

    return (
        <section>
            <Hero />
            <Howwork />
            <MielecInfo />
            <Elements />
        </section>
    )
}

import React, { useEffect, useState, useRef, useCallback } from "react";
import GlobalConfig from "../misc/GlobalConfig";

const GaleryInicesToLoad = 5;

export default function Galery() {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [lastGaleryIndexLoaded, setLastGaleryIndexLoaded] = useState(-1);
  const [modalSee, setmodalSee] = useState(false);
  const [visibility, setVisibility] = useState({ index: 0 });

  function sliderInterval(increment = 1, force = -1) {
    console.log("increment:", increment);
    setVisibility((prev) => {
      if (force >= 0) {
        return { index: force };
      }
      if (prev.index + increment >= rows.length) {
        return { index: 0 };
      } else if (prev.index + increment < 0) {
        return { index: rows.length - 1 };
      }
      return { index: prev.index + increment };
    });
  }

  // useEffect(() => {
  //   setRows([])
  // }, [query]);

  // function fetchGalery() {
  //   setLoading(true)
  //   fetch(GlobalConfig.backendHost+"gallery")
  //     .then((res) => {
  //       return res.json();
  //     })
  //     .then((data) => {
  //       const mappedRows = data.rows.map((row) => {
  //         return {
  //           file: arrayBufferToBase64(row._file.data),
  //           content: row._file_content,
  //         };
  //       });
  //       // setRows(mappedRows);
  //       setRows(prevRow => {
  //         return [...prevRow, ...mappedRows]
  //       })
  //       setHasMore(data.rows.length > 0)
  //       setLoading(false)
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // }

  function fetchGaleryPortion() {
    setLoading(true);
    fetch(
      GlobalConfig.backendHost +
        "gallery?" +
        new URLSearchParams({
          galeryIndexStart: lastGaleryIndexLoaded + 1,
          galeryInicesToLoad: GaleryInicesToLoad,
        })
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const mappedRows = data.rows.map((row) => {
          return {
            file: arrayBufferToBase64(row._file.data),
            content: row._file_content,
          };
        });
        // setRows(mappedRows);
        setRows((prevRow) => {
          return [...prevRow, ...mappedRows];
        });
        setLastGaleryIndexLoaded((lgil) => lgil + data.rows.length);
        setHasMore(data.rows.length === GaleryInicesToLoad);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    fetchGaleryPortion();
    // const galeryInterval = setInterval(sliderInterval, 2000);
    // return () => clearInterval(galeryInterval);
  }, []);

  function arrayBufferToBase64(buffer) {
    const enc = new TextDecoder("utf-8");
    const arr = new Uint8Array(buffer);
    return enc.decode(arr);
  }

  const observer = useRef();
  const lastRowReaded = useCallback(
    (element) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          console.log("Visible");
          fetchGaleryPortion();
        }
      });
      if (element) observer.current.observe(element);
    },
    [loading, hasMore]
  );

  return (
    <section className="gallery" id="gallery">
      <h3>Chcesz zobaczyć jakie realizujemy zlecenia?</h3>
      <p>
        W galerii znajdziesz jedynie namiastkę tego, czym do tej pory mieliśmy
        okazję zajmować. Niestety wielu projektów nie możemy udostępnić do
        wglądu, ze względu na brak zgody ze strony klientów. Druk 3D znajduje
        bowiem swoje zastosowanie w niemal nieograniczonej liczbie dziedzin.
      </p>
      <div className="gallery-row">
        {rows.map((row, index) => {
          return (
            <div className="gallery-boxes" ref={lastRowReaded}>
              <img src={row.file} alt="" width="300px" />
              <div className="gallery-content">
                <h3>{row.content}</h3>
                <button>
                  <p
                    className="gallery-button"
                    onClick={() => {
                      setmodalSee(true);
                      sliderInterval(0, index);
                    }}
                  >
                    Zobacz w galerii
                  </p>
                </button>
              </div>
            </div>
          );
        })}
        {/* {rows.length === 0 &&
          Array.from(Array(6)).map((_) => {
            return (
              <div className="gallery-boxes gallery-boxes-mock">
                <div className="image-mock"></div>
                <div className="gallery-content">
                  <div className="text-mock"></div>
                  <div className="button-mock"></div>
                </div>
              </div>
            );
          })} */}
      </div>
      <div
        className="modal-gallery"
        style={{ display: modalSee ? "block" : "none" }}
        onKeyDown={() => setmodalSee(false)}
      >
        {rows.map((row, index) => {
          return (
            <div
              className="gallery-big"
              style={{
                opacity: visibility.index === index ? "1" : "0",
                pointerEvents: visibility.index === index ? "all" : "none",
              }}
            >
              <img src={row.file} alt="" width="300px" />
              <button onClick={() => sliderInterval(-1)}>poprzedni</button>
              <button
                className="close-button"
                onClick={() => setmodalSee(false)}
              >
                Wyjdź
              </button>
              <button onClick={() => sliderInterval(1)}>następny</button>
            </div>
          );
        })}
      </div>
      <div>{loading && "Wczytywanie..."}</div>
    </section>
  );
}

import React from 'react'
import Printers from "./Printers"
import WhatDo from './WhatDo'
import { withRouter, Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";


function Offert() {
window.scrollTo(0, 0);

    return (
        <section className="offert">
            <h1 className="h2">W naszej ofercie znajdziesz: </h1>
            <WhatDo />
            <fieldset className="offert-box">
                <p>Specjalizujemy się w wydrukach niskoseryjnych nawet do <b>5000 sztuk</b> </p>
                <h2>Dlaczego to się opłaca?</h2>
                <p> Koszt wykonania formy wtryskowej zazwyczaj staje się ekonomiczny przy dużo większej partii produkcyjnej.</p>
            </fieldset>
            <div className="brown-backround">
                <h3>Przejdź do darmowej wyceny online, znajdziesz ją 
                <HashLink className="link-to-price" to="/wycena#pricing"> {">>"}tutaj{"<<"}</HashLink> i przekonaj się o niskich cenach  </h3>
            </div>
            <Printers />
        </section>
    )
}

export default withRouter(Offert);

import * as THREE from "three";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";

function getLoader(fileName) {
  if(fileName.toLowerCase().endsWith(".stl")) {
    return new STLLoader();
  } else if (fileName.toLowerCase().endsWith(".obj")) {
    return new OBJLoader();
  }
}

function loadFile(file, fileName) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const loader =getLoader(fileName);
      const geometry = loader.parse(e.target.result);
      resolve(geometry);
    };
    reader.readAsBinaryString(file);
  });
}

function getVolume(geometry) {
  if (!geometry.isBufferGeometry) {
    console.log("'geometry' must be an indexed or non-indexed buffer geometry");
    return 0;
  }
  var isIndexed = geometry.index !== null;
  let position = geometry.attributes.position;
  let sum = 0;
  let p1 = new THREE.Vector3(),
    p2 = new THREE.Vector3(),
    p3 = new THREE.Vector3();
  if (!isIndexed) {
    let faces = position.count / 3;
    for (let i = 0; i < faces; i++) {
      p1.fromBufferAttribute(position, i * 3 + 0);
      p2.fromBufferAttribute(position, i * 3 + 1);
      p3.fromBufferAttribute(position, i * 3 + 2);
      sum += signedVolumeOfTriangle(p1, p2, p3);
    }
  } else {
    let index = geometry.index;
    let faces = index.count / 3;
    for (let i = 0; i < faces; i++) {
      p1.fromBufferAttribute(position, index.array[i * 3 + 0]);
      p2.fromBufferAttribute(position, index.array[i * 3 + 1]);
      p3.fromBufferAttribute(position, index.array[i * 3 + 2]);
      sum += signedVolumeOfTriangle(p1, p2, p3);
    }
  }
  return sum;
}

function signedVolumeOfTriangle(p1, p2, p3) {
  return p1.dot(p2.cross(p3)) / 6.0;
}

function getFirstMeshObject(group) {
  return group.children.filter(child => child.type === "Mesh")[0];
}

export default function calculateGeometryParameters(file, fileName, unit) {
  if (!file) {
    return Promise.resolve({
      x: 0,
      y: 0,
      z: 0,
      volume: 0,
      boxVolume: 0,
      unit: "mm",
    });
  }

  return new Promise((resolve) => {
    loadFile(file, fileName).then((geometry) => {

      
      if(fileName.toLowerCase().endsWith(".stl")) {
      } else if (fileName.toLowerCase().endsWith(".obj")) {
        console.log(geometry);
        geometry = getFirstMeshObject(geometry).geometry;
      }
      let sizeVector = new THREE.Vector3();
      geometry.computeBoundingBox();
      geometry.boundingBox.getSize(sizeVector);
      
      const multiplayer = unit === "mm" ? 1 : 1000;
      // const multiplayer = 1;
      resolve({
        x: sizeVector.x * multiplayer,
        y: sizeVector.y * multiplayer,
        z: sizeVector.z * multiplayer,
        volume: getVolume(geometry) * multiplayer ** 3,
        boxVolume:
          sizeVector.x * sizeVector.y * sizeVector.z * multiplayer ** 3,
        unit: "mm",
      });
    });
  });
}

import React, { useEffect, useState } from "react";
import materialContent from "./materialContent";
import MaterialTable from "./MaterialTable";
import GlobalConfig from "../misc/GlobalConfig";

export default function Printers() {
  const [materials, setMaterials] = useState([]);

  useEffect(() => {
    fetch(GlobalConfig.backendHost+"materials")
      .then((res) => res.json())
      .then((data) => {
        let materialsNames = [];
        let result = [];
        data.rows.forEach((row) => {
          if (!materialsNames.includes(row._material_name)) {
            materialsNames.push(row._material_name);
          }
        });
        materialsNames.forEach((materialName) => {
          const properties = data.rows
            .filter((row) => row._material_name === materialName)
            .map((row) => row._property)
            .filter((property) => property);
          const applications = data.rows
            .filter((row) => row._material_name === materialName)
            .map((row) => row._application)
            .filter((application) => application);

          result.push({
            name: materialName,
            properties: properties,
            applications: applications,
          });
        });
        setMaterials(result);
      });
  }, []);

  return (
    <section
      className="printers"
      vocab="https://schema.org/"
      typeof="CreativeWork"
    >
      <div className="printer-about">
        <h2 className="about-printers">
          <span>Oferujemy najlepsze </span>
          <span property="keyword">technologie wydruku 3D</span>
        </h2>
        <div className="priners-list">
          <ul>
            <h2 property="alternativeHeadline">
              <span property="keyword">FDM/FFF</span>
            </h2>
            <li>- Maksymalny rozmiar wydruku standard 350 x 350 x 400mm (L x W x H)</li>
            <li>
            - Duża ilość tworzyw do wyboru w tym tworzywa techniczne
            </li>
            <li>- Przyjmujemy do realizacji wydruki o rozmiarze do 1000 x 1000 x 600mm – zapytanie poprzez formularz kontaktowy</li>
            <li>
            - Post-processing – usuwanie podpór, szlifowanie elektrokorundem, malowanie wygładzanie chemiczne (dla ABS, Calibram)
            </li>
            <li>
            - Kilka grup dokładności do wyboru: Ultra (wysokość warstwy 0.10mm), Standard (wysokość warstwy 0.18mm), Zgrubna (wysokość warstwy 0.25 i więcej)
            </li>
          </ul>
          <div>
            <img className="img-printers" src="img/flash.png" alt="flash" />
          </div>
        </div>
        <div className="priners-list">
          <div>
            <img
              className="img-printers"
              src="img/phrozen-sonic.jpg"
              alt="phrozen-sonic"
            />
          </div>
          <ul>
            <h2 property="alternativeHeadline">
              <span property="keyword">druk SLA/DLP</span>
            </h2>
            <li>- Rozmiar maksymalny 190 x 120 x 240 mm (D x SZ x W)</li>
            <li>- Dostępne rodzaje żywic: Standard, Tough, Flex</li>
            <li>- Bardzo wysokie odwzorowanie detali (dokładność wymiarowa do 0.05mm) </li>
            <li>- Możliwość wykonania dalszej obróbki (szlifowanie, podkładowanie, malowanie)</li>
            <li>- Technologia sprawdza się tam gdzie jakość i detale mają duże znaczenie</li>
          </ul>
        </div>
        <a id="materials-list"></a>
        <h2 className="mat-title">Wybierz materiał z naszej oferty</h2>
        <div className="rendering">
          {materials.map((mt) => {
            return (
              <MaterialTable
                name={mt.name}
                property={mt.properties}
                appilance={mt.applications}
              />
            );
          })}
        </div>
      </div>
      {/* <div className="printer-about-img">
                <img src="img/venom.png" alt="obraz3D" />
            </div> */}
    </section>
  );
}

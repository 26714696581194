import React, { useEffect, useRef } from "react";

export default function Unit(props){

    const defaultChecked = useRef(null)

    useEffect(() => {
        defaultChecked.current.checked = true;
    }, [])

    function handleClick(evt) {
        props.setUnit(evt.target.value);
    }

    return(
    <fieldset>
    <legend>Jednostka</legend>
    <table>
      <tr>
        <th> 
          <input type="radio" name="unit" value="mm" onClick={handleClick} ref={defaultChecked}/>
        </th>
        <th>
          <p>mm</p>
        </th>
      </tr>
      <tr>
        <th>
          <input type="radio" name="unit" value="cal" onClick={handleClick}/> 
        </th>
        <th>
          <p>cal</p>
        </th>
      </tr>
    </table>
  </fieldset>
    )
}
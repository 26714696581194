import React, { useEffect, useRef } from "react";

export default function Tooling(props) {
  const fieldset = useRef(null);

  useEffect(() => {
    if (props.tooling.length === 0) {
      return;
    }

    console.log(props.tooling);

    fieldset.current.querySelector('input[name="bez obróbki"]').checked = true;

    props.setTooling((prev) => {
      return [
        ...prev,
        props.tooling.filter((tool) => tool._type === "bez obróbki")[0],
      ];
    });
  }, [props.tooling]);

  function handleTooling(ev) {
    props.setTooling((prev) => {
      if (ev.target.checked) {
        let ret = [
          ...prev,
          props.tooling.filter((tool) => tool._type === ev.target.name)[0],
        ];
        if (ret.length > 1) {
          ret = ret.filter((r) => r._type != "bez obróbki");
          ev.target.parentNode.parentNode.querySelector(
            'input[name="bez obróbki"]'
          ).checked = false;
        }
        if (ev.target.name === "bez obróbki") {
          ret = [
            props.tooling.filter((tool) => tool._type === ev.target.name)[0],
          ];
          ev.target.parentNode.parentNode
            .querySelectorAll('input:not([name="bez obróbki"])')
            .forEach((input) => (input.checked = false));
          ev.target.parentNode.parentNode.querySelector(
            'input[name="bez obróbki"]'
          ).checked = true;
        }
        return ret;
      } else {
        let ret = prev.filter((tool) => tool._type != ev.target.name);
        if (ret.length === 0) {
          ret = [
            props.tooling.filter((tool) => tool._type === "bez obróbki")[0],
          ];
          ev.target.parentNode.parentNode.querySelector(
            'input[name="bez obróbki"]'
          ).checked = true;
        }
        return ret;
      }
    });
  }

  return (
    <fieldset className="select" ref={fieldset}>
      <legend>Obróbka dodatkowa</legend>
      {props.tooling.map((tool) => {
        return (
          <div className="tooling-option">
            <input
              type="checkbox"
              name={tool._type}
              id={tool._type.toLowerCase().replace(/ /g, "-")}
              onChange={handleTooling}
            />
            <label htmlFor={tool._type.toLowerCase().replace(/ /g, "-")}>
              {tool._type}
            </label>
          </div>
        );
      })}
    </fieldset>
  );
}

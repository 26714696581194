import React from 'react'
import { withRouter, Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

function Elements() {
    return (
        <section className="section-elements" vocab="https://schema.org/" typeof="CreativeWork">
            <h2>Zastanawiasz się co możesz u nas wydrukować?</h2>
            <p>Swoje usługi kierujemy głównie do firm z wielu branż, oraz hobbystów i majsterkowiczów:</p>
                <p><strong>przemysł:</strong> oprzyrządowania linii produkcyjnych, narzędzia specjalistyczne, sprawdziany, matryce na praski, sprawdziany poprawności montażu, narzędzia Poka-Yoke, prototypy, obudowy na elektronikę ESD, modele do tworzenia form odlewniczych i wiele innych</p>
                <p><strong>motoryzacja:</strong>niedostępne części zamienne, części do samochodów zabytkowych oraz customowych, wyposażenia warsztatów</p>
                <p><strong>inne:</strong> szuflady narzędziowe typu Poka-Yoke,  LOGA firmowe w 3D, litery trójwymiarowe, makiety obiektów, budynków, topografia terenu, wystawy obiektów w muzeach skansenach, modele dla szkolnictwa narządy, kości, szkielety.</p>
                
                  
            <p>Poniżej zamieszczamy namiastkę Naszych realizacji, pragniemy zaznaczyć, że możliwości <strong>druku 3D</strong> są niemal nieograniczone, ograniczeniem, jest jedynie wyobraźnia :)</p>
            
            <div className="elements-box">
                <div property="exampleOfWork" typeof="CreativeWork" className="element">
                    <img property="image" typeof="ImageObject" src="img/element2.png" alt="elementy montarzowe" />
                    <h3 property="keyword">Elementy montażowe</h3>
                </div>
                <div property="workExample" typeof="CreativeWork" className="element">
                    <img property="image" typeof="ImageObject" src="img/turbinka.jpg" alt="turbinka" />
                    <h3 property="keyword">Prototypy</h3>
                </div>
                <div property="workExample" typeof="CreativeWork" className="element">
                    <img property="image" typeof="ImageObject" src="img/lampka.png" alt="lampka" />
                    <h3 property="keyword">Przedmioty codziennego użytku</h3>
                </div>
                <div property="workExample" typeof="CreativeWork" className="element">
                    <img property="image" typeof="ImageObject" src="img/model.jpg" alt="model" />
                    <h3 property="keyword">Modele kolekcjonerskie</h3>
                </div>
            </div>
            <p>Przejdź do <HashLink className="link-to-price" to="/realizacje#cart-header">galerii</HashLink>  i zobacz nasze realizacje</p>

           <p>Gwarantujemy, że nasze produkty są <strong>najlepszej klasy</strong> </p> 
        </section>
    )
}

export default withRouter(Elements);


const ENV = {
  DEV: "DEV",
  PROD: "PROD",
};

const selectedEnvironment = ENV.PROD;

const GlobalConfig = {
  backendHost:
    selectedEnvironment === ENV.PROD ? "/" : "http://localhost:8000/",
};

export default GlobalConfig;
